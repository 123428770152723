import {Fly, FlyRequestConfig, FlyResponse} from "flyio"
import {localStorageGet, localStorageSet} from "../../utils/web"
import Cookies from 'js-cookie'
import {jsBridge} from "../../utils/web"
import {PlatformEnum, TokenEnum} from "../../../shared/enum"
import {FDEventEmitter, NO_SINGLE} from "../../utils/event-emitter"
import {tokenCommon} from "../common"
import {ConfigModel, IAccessor, TokenConfig} from "../../../shared/models"
import {addRequestBeforeHook, addResponseAfterHook, getHttpClient} from "../../request/h5"
import {RequestEnum} from "../../../shared/enum"
import {urlParse} from "../../utils/utils"

// token是否失效
let isTokenInvalid = false

const {
  tokenInvalidHook,
  refreshTokenSuccess,
  refreshTokenFail,
  config: setConfig,
  getConfig,
  addTokenInvalidHook,
  removeTokenInvalidHook,
  addRefreshTokenSuccess,
  removeRefreshTokenSuccess,
  addRefreshTokenFail,
  removeRefreshTokenFail,
} = tokenCommon()

export {
  addTokenInvalidHook,
  removeTokenInvalidHook,
  addRefreshTokenSuccess,
  removeRefreshTokenSuccess,
  addRefreshTokenFail,
  removeRefreshTokenFail,
}

export function getToken(): string {
  return Cookies.get(TokenEnum.TOKEN_KEY)
    || Cookies.get(TokenEnum.TOKEN_KEY2)
    || localStorageGet(TokenEnum.TOKEN_KEY)
    || localStorageGet(TokenEnum.TOKEN_KEY2)
}

export function getRefreshToken(): string {
  return localStorageGet(TokenEnum.REFRESH_TOKEN_KEY) || localStorageGet(TokenEnum.REFRESH_TOKEN_KEY2)
}

export function setToken(token: string) {
  Cookies.remove(TokenEnum.TOKEN_KEY)
  Cookies.remove(TokenEnum.TOKEN_KEY2)
  localStorageSet(TokenEnum.TOKEN_KEY, token)
  localStorageSet(TokenEnum.TOKEN_KEY2, token)
  Cookies.set(TokenEnum.TOKEN_KEY, token)
  Cookies.set(TokenEnum.TOKEN_KEY2, token)
}

export function setRefreshToken(refreshToken: string) {
  localStorageSet(TokenEnum.REFRESH_TOKEN_KEY, refreshToken)
  localStorageSet(TokenEnum.REFRESH_TOKEN_KEY2, refreshToken)
}

export function tokenInterceptorMountForH5(config: TokenConfig) {

  // 刷新token
  const flushToken = (refreshToken: string) => {
    // 实例化新的ajax对象，并且不走钩子函数
    const $http = getHttpClient({ ...config, newInstance: true, hookDisable: true, filterResponseResult: true })
    const refreshTokenUrl = config.refreshTokenUrl || `${config.baseURL.includes('/api') ? '' : '/Api'}/User/refreshToken`
    return $http.post(refreshTokenUrl, { refresh_token: refreshToken })
  }

  const tokenInterceptorForRequestHook = (request: FlyRequestConfig, $http: Fly) => {
    // 添加header头/添加token
    const token = getToken()
    request.headers[RequestEnum.ACCESS_TOKEN_HEADER_KEY] = token || ''
  }

  const tokenInterceptorForResponseHook = (
    response: FlyResponse,
    $http: Fly,
    accessor: IAccessor /* 访问器对象 */
  ) => {
    const { code, data, msg } = response.data
    let refreshToken: string;
    // 刷新token完成
    const flushTokenDone = (token: string) => {
      if (token) {
        // 刷新token
        setToken(token)
        setRefreshToken('')
        refreshTokenSuccess.execList(response, $http)
      } else {
        // 如果失败则通知refreshTokenFail钩子列表
        refreshTokenFail.execList(response, $http)
      }
      isTokenInvalid = false
      // 放开请求
      $http.unlock();
      // 通知请求在token失效前就已经响应的请求，触发重试任务
      accessor.task.emit(RequestEnum.ACCESSOR_REPEAT_REQUEST)
    }

    // 正常请求
    if (code === 1) {
      const newToken = (data && data.new_token) || (data && data.user_info && data.user_info.new_token)
      if (newToken) {
        newToken.access_token && setToken(newToken.access_token)
        newToken.refresh_token && setRefreshToken(newToken.refresh_token)
      }
    }
    // token失效，通知tokenInvalid钩子列表
    if ([-1, -2].includes(code)) {
      $http.lock()
      refreshToken = getRefreshToken()
      // 删除缓存
      setToken('')
      setRefreshToken('')
      tokenInvalidHook.execList(response, $http)
      // 标识此次请求需要重试
      accessor.repeatRequest = true
      if (!isTokenInvalid) {
        isTokenInvalid = true
        // web app内登录失效
        if (code === -1 && config.platform === PlatformEnum.App) {
          jsBridge(bridge => {
            // 用事件管理确保每次CGWebSendToken回调只会触发一次请求重试
            const event = new FDEventEmitter(NO_SINGLE)
            event.once('CGWebSendToken.called', (token: string) => {
              flushTokenDone(token)
            })
            // 注册登录
            bridge.registerHandler('CGWebSendToken', (token) => {
              event.emit('CGWebSendToken.called', token)
            })
            // 去登录
            bridge.callHandler('CGOpenLoginPage', {
              // code,
              // data,
              // msg,
              // body: response.request.body,
              // headers: response.request.headers,
              // request: response.request
            }, () => { })
          })
          // web app内token过期
        } else if (code === -2 && config.platform === PlatformEnum.App) {
          jsBridge(bridge => {
            bridge.callHandler('CGRefreshToken', {
              // code,
              // data,
              // msg,
              // body: response.request.body,
              // headers: response.request.headers,
              accessToken: response.request.headers[RequestEnum.ACCESS_TOKEN_HEADER_KEY],
            }, (token: string) => {
              flushTokenDone(token)
            })
          })
          // h5内token过期
        } else if (code === -2 && config.platform === PlatformEnum.H5) {
          // 调用刷新函数
          flushToken(refreshToken).then(result => {
            // 判断刷新函数结果，成功则通知refreshTokenSuccess钩子列表，并重新执行重置列表
            if (result.code === 1) {
              // 刷新token
              setToken(result.data.access_token)
              setRefreshToken(result.data.refresh_token)
              refreshTokenSuccess.execList(response, $http)
            } else {
              // 如果失败则通知refreshTokenFail钩子列表
              refreshTokenFail.execList(response, $http)
            }
            isTokenInvalid = false
            // 放开请求
            $http.unlock()
            // 通知请求在token失效前就已经响应的请求，触发重试任务
            accessor.task.emit('accessor.repeatRequest')
          })
        } else {
          isTokenInvalid = false
          $http.unlock()
        }
      }
    }
  }

  addRequestBeforeHook(tokenInterceptorForRequestHook)
  addResponseAfterHook(tokenInterceptorForResponseHook)
}

export function tokenHookMount(config: TokenConfig) {
  let platform: PlatformEnum = PlatformEnum.H5
  const query = urlParse()
  console.log('tokenHookMount route query', query)
  // 如果是在web app内
  if (query.isapp || query.webview) {
    platform = PlatformEnum.App
  }
  setConfig({ ...config, platform })
  tokenInterceptorMountForH5(getConfig())
}


