/**
 * 返回当前url
 */
export const currentURL = () => window.location.href

/**
 * 获取url参数（第一种）
 * @param {*} key // 指定的key
 * @param {*} origin
 */
export function getUrlParam(key: string, origin = null) {
  const reg = new RegExp(`(^|&)${key}=([^&]*)(&|$)`)
  let r = null
  if (origin == null) {
    r = window.location.search.substr(1).match(reg)
  } else {
    r = origin.substr(1).match(reg)
  }
  if (r != null) return decodeURIComponent(r[2])
  return null
}

/**
 * 获取url参数（第二种）
 * @param {*} name
 * @param {*} origin
 */
export function getUrlParams(name: string, origin = null) {
  const url = location.href
  const temp1 = url.split('?')
  const pram = temp1[1]
  const keyValue = pram.split('&')
  const obj = {}
  for (let i = 0; i < keyValue.length; i++) {
    const item = keyValue[i].split('=')
    const key = item[0]
    const val = item[1]
    obj[key] = val
  }
  return obj[name]
}

/**
 * 修改url中的参数
 * @param { string } paramName
 * @param { string } replaceWith
 */
export function replaceParamVal(paramName: string, replaceWith: string) {
  const oUrl = window.location.href.toString()
  // eslint-disable-next-line
  const re = /(${paramName}=)([^&]*)/gi
  window.location.href = oUrl.replace(re,`${paramName}=${replaceWith}`)
  return window.location.href
}

/**
 * 删除url中指定的参数
 * @param { string } name
 */
export function funcUrlDel(name){
  const local = window.location
  const baseUrl = `${local.origin}${local.pathname}?`
  const query = local.search.substr(1)
  if (query.indexOf(name)>-1) {
    const obj = {}
    const arr = query.split('&')
    const arr2 = new Array(arr)
    for (var i = 0; i < arr.length; i++) {
      arr2[i] = arr[i].split('=')
      obj[arr[i][0]] = arr[i][1]
    }
    delete obj[name]
    const url = baseUrl + JSON.stringify(obj).replace(/[\"\{\}]/g,'').replace(/\:/g,'=').replace(/\,/g,'&')
    return url
  }
}

/**
 * 获取窗口可视范围的高度
 */
export function getClientHeight(): number {
  let clientHeight = 0;
  if (document.body.clientHeight && document.documentElement.clientHeight) {
    clientHeight = (document.body.clientHeight < document.documentElement.clientHeight) ? document.body.clientHeight : document.documentElement.clientHeight
  }
  else {
    clientHeight = (document.body.clientHeight > document.documentElement.clientHeight) ? document.body.clientHeight : document.documentElement.clientHeight
  }
  return clientHeight
}

/**
 * 获取窗口可视范围宽度
 */
export function getPageViewWidth(): number {
  let d = document,
    a = d.compatMode == "BackCompat" ? d.body : d.documentElement
  return a.clientWidth
}

/**
 * 获取窗口宽度
 */
export function getPageWidth(): number {
  let g = document,
    a = g.body,
    f = g.documentElement,
    d = g.compatMode == "BackCompat" ? a : g.documentElement
  return Math.max(f.scrollWidth, a.scrollWidth, d.clientWidth)
}


/**
 * 获取窗口尺寸
 */
export function getViewportOffset(): { w: number; h: number } {
  if (window.innerWidth) {
    return {
      w: window.innerWidth,
      h: window.innerHeight
    }
  } else {
    // ie8及其以下
    if (document.compatMode === "BackCompat") {
      // 怪异模式
      return {
        w: document.body.clientWidth,
        h: document.body.clientHeight
      }
    } else {
      // 标准模式
      return {
        w: document.documentElement.clientWidth,
        h: document.documentElement.clientHeight
      }
    }
  }
}

/**
 * 获取滚动条距顶部高度
 */
export function getPageScrollTop() {
  let a = document
  return a.documentElement.scrollTop || a.body.scrollTop
}

/**
 * 获取滚动条距左边的高度
 */
export function getPageScrollLeft() {
  let a = document
  return a.documentElement.scrollLeft || a.body.scrollLeft
}

/**
 * 开启全屏
 * @param {*} element
 */
export function launchFullscreen(element: any) {
  if (element.requestFullscreen) {
    element.requestFullscreen()
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen()
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen()
  } else if (element.webkitRequestFullscreen) {
    element.webkitRequestFullScreen()
  }
}

/**
 * 关闭全屏
 */
export function exitFullscreen() {
  const d = document as any
  if (d.exitFullscreen) {
    d.exitFullscreen()
  } else if (d.msExitFullscreen) {
    d.msExitFullscreen()
  } else if (d.mozCancelFullScreen) {
    d.mozCancelFullScreen()
  } else if (d.webkitExitFullscreen) {
    d.webkitExitFullscreen()
  }
}

/**
 * 返回当前滚动条位置
 * @param el
 */
export const getScrollPosition = (el: any = window): { x: number; y: number } => ({
  x: el.pageXOffset !== undefined ? el.pageXOffset : el.scrollLeft,
  y: el.pageYOffset !== undefined ? el.pageYOffset : el.scrollTop
})


/**
 * http跳转https
 */
export const httpsRedirect = () => {
  if (location.protocol !== 'https:') location.replace('https://' + location.href.split('//')[1])
}

/**
 * 检查页面底部是否可见
 */
export const bottomVisible = (): boolean =>{
  return document.documentElement.clientHeight + window.scrollY >=
    (document.documentElement.scrollHeight || document.documentElement.clientHeight)
}

/**
 * @param { string } url
 * @param { string } windowName
 * @param { number } width
 * @param { number } height
 */
export function openWindow(url: string, windowName: string, width: number, height: number) {
  // @ts-ignore
  const x = parseInt(screen.width / 2.0) - width / 2.0;
  // @ts-ignore
  const y = parseInt(screen.height / 2.0) - height / 2.0;
  const isMSIE = navigator.appName == "Microsoft Internet Explorer"
  if (isMSIE) {
    var p = "resizable=1,location=no,scrollbars=no,width="
    p = p + width;
    p = p + ",height=";
    p = p + height;
    p = p + ",left=";
    p = p + x;
    p = p + ",top=";
    p = p + y;
    window.open(url, windowName, p);
  } else {
    const win = window.open(
      url,
      "ZyiisPopup",
      "top=" +
      y +
      ",left=" +
      x +
      ",scrollbars=" +
      scrollbars +
      ",dialog=yes,modal=yes,width=" +
      width +
      ",height=" +
      height +
      ",resizable=no"
    );
    try { win.resizeTo(width, height); } catch(e) { }
    win.focus()
  }
}

/**
 * 自适应页面（rem）
 * @param { number } width
 */
export function AutoResponse(width = 750) {
  const target = document.documentElement
  target.clientWidth >= 600
    ? (target.style.fontSize = "80px")
    : (target.style.fontSize = target.clientWidth / width * 100 + "px")
}

