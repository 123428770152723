import {forkJoin, from, throwError} from 'rxjs'
import {catchError, map, reduce} from 'rxjs/operators'
import {fromPromise} from "rxjs/internal-compatibility";

export type JudgmentFormula = (...args: any[]) => any

/**
 * 执行列表接口定义
 * 此列表维护一个函数列表，
 */
export declare interface ExecutableList {
  add(fun: JudgmentFormula)
  remove(fun: JudgmentFormula)
  execList(...args: any[]): Promise<boolean>
}

export class ExecutableQueue implements ExecutableList {
  _list: JudgmentFormula[]
  _canExecBeInterrupted: boolean = true

  constructor(canExecBeInterrupted?: boolean) {
    this._list = []
    this._canExecBeInterrupted = canExecBeInterrupted
  }

  add(fun: JudgmentFormula) {
    this._list = [...this._list, fun]
  }

  remove(fun: JudgmentFormula) {
    this._list = this._list.filter(fun2 => fun !== fun2)
  }

  execList(...args: any[]): Promise<boolean> {
    return new Promise((resolve1, reject1) => {
      from(this._list)
        .pipe(
          map(func => {
            return fromPromise(new Promise(async (resolve2, reject2) => {
              try {
                const judgmentResult = await func(...args)
                // 剩余列表执行权是否由当前函数执行返回值控制
                if (!this._canExecBeInterrupted) resolve2(judgmentResult)
                if (judgmentResult) {
                  resolve2(judgmentResult)
                } else {
                  reject2(new Error('The judgment is bad.'))
                }
              } catch (e) {
                reject2(e)
              }
            }))
          }),
          reduce((count, res) => count + 1, 0)
        ).subscribe(
          (cunt) => resolve1(true),
        (err) => reject1(false)
        )
    })
  }
}
