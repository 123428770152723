 /* eslint-disable no-undef */
import { getIsAndroid, getIsIOS } from './devices';

type Callback = (bridge: any) => void

let isInit = false

// eslint-disable-next-line consistent-return
export function jsBridge(callback: Callback) {
  const w = window as any;
  const isAndroid = getIsAndroid()
  const isIOS = getIsIOS()
  const wvjbcbs: Callback[] = w.WVJBCallbacks
  if (isIOS) {
    if (w.WebViewJavascriptBridge) return callback(w.WebViewJavascriptBridge)
    if (wvjbcbs) return wvjbcbs.push(callback);
    w.WVJBCallbacks = [callback]
    const WVJBIframe = document.createElement('iframe')
    WVJBIframe.style.display = 'none'
    WVJBIframe.src = 'https://__bridge_loaded__'
    document.documentElement.appendChild(WVJBIframe)
    setTimeout(() => { document.documentElement.removeChild(WVJBIframe); }, 0)
  } else if (isAndroid) {
    if (w.WebViewJavascriptBridge) {
      // android首次加载需要先执行init
      if (!isInit) {
        isInit = true
        w.WebViewJavascriptBridge.init((message: any, responseCallback: Function) => responseCallback())
      }
      callback(w.WebViewJavascriptBridge)
    } else {
      document.addEventListener('WebViewJavascriptBridgeReady', () => {
        // android首次加载需要先执行init
        if (!isInit) {
          isInit = true
          w.WebViewJavascriptBridge.init((message: any, responseCallback: Function) => responseCallback())
        }
        callback(w.WebViewJavascriptBridge)
      }, false)
    }
  }
}



