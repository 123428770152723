import {ExecutableQueue, JudgmentFormula} from "../utils/executable-queue"
import {PlatformEnum} from "../../shared/enum"
import {TokenConfig} from "../../shared/models"

export function tokenCommon() {

  let configCache: TokenConfig = {
    platform: PlatformEnum.H5
  }

  const tokenInvalidHook: ExecutableQueue = new ExecutableQueue(false)
  const refreshTokenSuccess: ExecutableQueue = new ExecutableQueue(false)
  const refreshTokenFail: ExecutableQueue = new ExecutableQueue(false)

  function config(cfg: TokenConfig) {
    configCache = { ...configCache, ...cfg }
  }

  function getConfig() {
    return configCache
  }

  function addTokenInvalidHook(func: JudgmentFormula) {
    tokenInvalidHook.add(func)
  }

  function removeTokenInvalidHook(func: JudgmentFormula) {
    tokenInvalidHook.remove(func)
  }

  function addRefreshTokenSuccess(func: JudgmentFormula) {
    refreshTokenSuccess.add(func)
  }

  function addRefreshTokenFail(func: JudgmentFormula) {
    refreshTokenFail.add(func)
  }

  function removeRefreshTokenSuccess(func: JudgmentFormula) {
    refreshTokenSuccess.remove(func)
  }

  function removeRefreshTokenFail(func: JudgmentFormula) {
    refreshTokenFail.remove(func)
  }

  /**
   1、获取token-getToken
   2、添加/删除token失效钩子函数-（add/remove)TokenInvalidHook
   3、添加/删除刷新token(成功/失败)钩子函数-(add/remove)RefreshToken(Success/Fail)
   */
  return {
    tokenInvalidHook,
    refreshTokenSuccess,
    refreshTokenFail,
    config,
    getConfig,
    addTokenInvalidHook,
    removeTokenInvalidHook,
    addRefreshTokenSuccess,
    removeRefreshTokenSuccess,
    addRefreshTokenFail,
    removeRefreshTokenFail,
  }
}
