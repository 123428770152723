// 获取元素style
export const getStyle = (obj, attr: string): string => {
  if (obj.currentStyle) {
    return obj.currentStyle[attr];
  }
  return document.defaultView.getComputedStyle(obj, null)[attr];
};

/**
 * 隐藏所有指定标签
 * 例: hide(document.querySelectorAll('img'))
 */
export const hideTag = (...el) => [...el].forEach(e => (e.style.display = 'none'));

/**
 * 检查是否包含子元素
 * @param { element } parent
 * @param { element } child
 * 例：elementContains(document.querySelector('head'), document.querySelector('title')); // true
 */
export const elementContains = (parent, child) => parent !== child && parent.contains(child);
