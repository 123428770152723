import { getWindowRect } from './devices';

export function scroll() {
  setTimeout(() => {
    const scrollHeight = document.documentElement.scrollTop || document.body.scrollTop || 0;
    window.scrollTo(0, Math.max(scrollHeight - 1, 0));
  }, 100);
}

// 滚动到指定元素
export const smoothScroll = (e) => {
  const el = document.querySelector(e);
  if (el) {
    el.scrollIntoView({ behavior: 'smooth' });
  }
};

// 平滑滚动到顶部
export const scrollToTop = () => {
  const c = document.documentElement.scrollTop || document.body.scrollTop;
  if (c > 0) {
    window.requestAnimationFrame(scrollToTop);
    window.scrollTo(0, c - c / 8);
  }
};

// 平滑滚动到某个位置
export const scrollToEl = (e, offset = 0) => {
  // 最大重复次数
  let count = 1000;
  function loop() {
    // 页面位置信息
    const windowRect = getWindowRect();
    const { height: bodyHeight } = document.body.getBoundingClientRect(); // 当前页面内容总高度
    const bottomDistance = bodyHeight - windowRect.height; // 可滚动距离的最大值
    const c = window.scrollY;
    const el = document.querySelector(e);
    if (el) {
      const { top } = el.getBoundingClientRect();
      const targetDistance = top - offset;
      const stageDistance = Math.abs(targetDistance); // 当前位置距离目标位置
      if (stageDistance > 1 && count > 0) {
        count -= 1;
        window.requestAnimationFrame(() => window.scrollY < bottomDistance - 1 && loop());
        const speed = stageDistance > 10 ? stageDistance / 8 : stageDistance; // 当距离位置大于10时，一直以距目标距离8分之一的速度递减
        const scrollDistamce = targetDistance > 0 ? c + speed : c - speed; // 滚动的距离
        // console.log(`targetDistance:${targetDistance}, stageDistance:${stageDistance}, speed:${speed}, scrollDistamce:${scrollDistamce}`);
        window.scrollTo(0, scrollDistamce);
      } else {
        count = 1000;
      }
    }
  }
  loop();
};
