import {Fly, FlyRequestConfig, FlyResponse} from "flyio"
import {localStorageGet, localStorageSet} from "../../utils/web"
import {PlatformEnum, TokenEnum} from "../../../shared/enum"
import {tokenCommon} from "../common"
import {addRequestBeforeHook, addResponseAfterHook, getHttpClient} from "../../request/pc"
import {ConfigModel, IAccessor, IResult, TokenConfig} from "../../../shared/models"
import {RequestEnum} from "../../../shared/enum"

// token是否失效
let isTokenInvalid = false

const {
  tokenInvalidHook,
  refreshTokenSuccess,
  refreshTokenFail,
  config: setConfig,
  getConfig,
  addTokenInvalidHook,
  removeTokenInvalidHook,
  addRefreshTokenSuccess,
  removeRefreshTokenSuccess,
  addRefreshTokenFail,
  removeRefreshTokenFail,
} = tokenCommon()

export {
  addTokenInvalidHook,
  removeTokenInvalidHook,
  addRefreshTokenSuccess,
  removeRefreshTokenSuccess,
  addRefreshTokenFail,
  removeRefreshTokenFail,
}

export function getToken(): string {
  return localStorageGet(TokenEnum.TOKEN_KEY)
}

export function getRefreshToken(): string {
  return localStorageGet(TokenEnum.REFRESH_TOKEN_KEY)
}

export function setToken(token: string) {
  localStorageSet(TokenEnum.TOKEN_KEY, token)
}

export function setRefreshToken(refreshToken: string) {
  localStorageSet(TokenEnum.REFRESH_TOKEN_KEY, refreshToken)
}

export default function tokenInterceptorMountForPc(config: TokenConfig) {
  // 刷新token
  const flushToken = (refreshToken: string) => {
    // 实例化新的ajax对象，并且不走钩子函数
    const $http = getHttpClient({ ...config, newInstance: true, hookDisable: true, filterResponseResult: true })
    const refreshTokenUrl = config.refreshTokenUrl || `${config.baseURL.includes('/api') ? '' : '/Api'}/User/refreshToken`
    return $http.post(refreshTokenUrl, { refresh_token: refreshToken })
  }

  const tokenInterceptorForRequestHook = (request: FlyRequestConfig, $http: Fly) => {
    // 添加header头/添加token
    const token = getToken()
    request.headers[RequestEnum.ACCESS_TOKEN_HEADER_KEY] = token || ''
  }

  const tokenInterceptorForResponseHook = (
    response: FlyResponse,
    $http: Fly,
    accessor: IAccessor /* 访问器对象 */
  ) => {
    const { code, data } = response.data
    let refreshToken: string;

    // 正常请求
    if (code === 1) {
      const newToken = (data && data.new_token) || (data && data.user_info && data.user_info.new_token)
      if (newToken) {
        newToken.access_token && setToken(newToken.access_token)
        newToken.refresh_token && setRefreshToken(newToken.refresh_token)
      }
    }
    // token失效，通知tokenInvalid钩子列表
    if ([-1, -2].includes(code)) {
      $http.lock()
      refreshToken = getRefreshToken()
      // 删除缓存
      setToken('')
      setRefreshToken('')
      tokenInvalidHook.execList(response, $http)
      // 标识此次请求需要重试
      accessor.repeatRequest = true
      if (!isTokenInvalid) {
        isTokenInvalid = true
        if (code === -2) {
          // 调用刷新函数
          flushToken(refreshToken).subscribe((result: IResult<any>) => {
            // 判断刷新函数结果，成功则通知refreshTokenSuccess钩子列表，并重新执行重置列表
            if (result.code === 1) {
              // 刷新token
              setToken(result.data.access_token)
              setRefreshToken(result.data.refresh_token)
              refreshTokenSuccess.execList(response, $http)
            } else {
              // 如果失败则通知refreshTokenFail钩子列表
              refreshTokenFail.execList(response, $http)
            }
            isTokenInvalid = false
            // 放开请求
            $http.unlock();
            // 通知请求在token失效前就已经响应的请求，触发重试任务
            accessor.task.emit(RequestEnum.ACCESSOR_REPEAT_REQUEST)
          })
        } else {
          isTokenInvalid = false
          $http.unlock()
        }
      }
    }
  }

  addRequestBeforeHook(tokenInterceptorForRequestHook)
  addResponseAfterHook(tokenInterceptorForResponseHook)
}

export function tokenHookMount(config: TokenConfig) {
  setConfig({ ...config, platform: PlatformEnum.Pc })
  tokenInterceptorMountForPc(getConfig());
}
