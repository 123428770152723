import { IDictionary } from "../../shared/models/dictionary.model";
import { isInValidParam } from "./validator";

// 用于生成uuid
export const S4 = (): string => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);

// 获取uid串
export const getUid = (): string => `${S4() + S4()}-${S4()}-${S4()}-${S4()}-${S4() + S4() + S4()}`;

/**
 * 路径反斜杠替换处理
 * 例如：https:\/\/img5.chungoulife.com\/201912\/pic536c98a54551e2b01c1efe350bfdb6f4.jpg
 * 在微信浏览器会出现无法解析上面这种路径问题
 */
export const formatUrl = (val: string): string => val.replace(/\\/, '/');

// 补零操作
export const repairZero = (n: string|number): string => (n < 10 ? `0${n}` : `${n}`);

// 判断数组是否为空
export const isEmptyArray = (arr): boolean => toString.call(arr) === '[object Array]' && arr.length === 0;

// 状态拷贝
export const copyState = (state: any): any => JSON.parse(JSON.stringify(state));
// 状态拷贝，可提倡多余字段
export const copyState2 = (originalState, ignoreFields = []) => {
  const state = {};
  Object.keys(originalState).forEach((key) => {
    if (!ignoreFields.includes(key)) state[key] = copyState(originalState[key]);
  });
  return state;
};

/**
 * 防抖函数
 * @param func 事件触发的操作
 * @param delay 间隔多少毫秒需要触发一次事件，默认200毫秒
 * @return {Function} 返回封装好函数
 */
export const debounce = (func: Function, delay: number = 200): Function => {
  let timer: any = null;
  return function handler(...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

/**
 * 节流函数
 * @param func 事件触发的操作
 * @param delay 间隔多少毫秒需要触发一次事件，默认200毫秒
 * @return {loop}
 */
export const throttle = (func: Function, delay: number= 300): Function=> {
  let timer: any = null;
  let startTime: number = Date.now();
  return function loop(...args) {
    const curTime: number = Date.now();
    const remaining: number = delay - (curTime - startTime);
    const context: any = this;
    clearTimeout(timer);
    if (remaining <= 0) {
      func.apply(context, args);
      startTime = Date.now();
    } else {
      timer = setTimeout(() => func.apply(context, args), remaining);
    }
  };
};

/**
 * 加锁包装函数(与防抖和节流函数类似)
 * 当有方法同时多次调用，但是只希望调用这个方法一次但未执行完成时，可以使用此包装函数
 * @param func Function 传入函数
 * @param onLockFunc Function 当被锁函数没运行完成时触发的函数
 * @return {Function}
 */
export const lockWrap = (func: Function, onLockFunc: Function): Function => {
  let lock: boolean = false;
  return async function handler(...args) {
    if (!lock) {
      lock = true;
      const result = await func.apply(this, args);
      lock = false;
      return result;
    }
    onLockFunc && onLockFunc.call(this);
  };
};

/**
 * 获取字符url查询数据
 * 参数 [url] 没有参数使用当前url进行解析，有参数时解析的为给出的参数
 * 返回值 {object} 返回一个查询字符串解析的key，value对对象，没有则返回空对象
 */
export const urlParse = (search: string = window.location.search): IDictionary<string> => {
  const obj: IDictionary<string> = {};
  const searchReg: RegExp = /[?&]?\w+=[^?&#]+/g;
  const res: string[] = search.match(searchReg) || [];
  res.forEach((kv: string) => {
    const [key, val] = kv.substr(1).split('=');
    obj[key] = val;
  });
  return obj;
};

// 排除指定参数后返回指定字段
export const getOtherQuery = (query: any = {}, excludes: string[] = ['code']) =>
  Object.keys(query).reduce((acc: IDictionary<string>, cur: string) => {
    if (excludes.indexOf(cur) === -1) acc[cur] = query[cur];
    return acc;
  }, {});

// 路径查询对象使用&字符串拼合
export const queryString = (query: any = {}): string =>
  Object.keys(query)
    .reduce((strArr, key) => strArr.concat([`${key}=${query[key]}`]), [])
    .join('&');

// 转换为数字
export const toNumber = (n: any): number => {
  if (isInValidParam(n)) return 0;
  // eslint-disable-next-line
  return isNaN(Number(n)) ? 0 : Number(n);
};

// 是否是地址
export const isUrl = (str: string): boolean => new RegExp(/httpPc(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?|.+\.(jpg|jpeg|png|gif|bmp)$/).test(str);

// 过滤无效数据；
export const filterTime = (a: number, b: number): number|undefined => {
  return (a > 0 && b > 0 && (a - b) >= 0) ? (a - b) : undefined;
}

// 获得某个范围内的随机数
export const random = (min: number, max?: number): number => {
  if (min !== void 0 && max === void 0) {
    max = min;
    min = 0;
  }
  if (max - min <= 0) return 0;
  return Math.random() * (max - min) + min;
}

/**
 * 金钱格式化，三位加逗号
 *  @param { number } num
 */
export const formatMoney = num => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

/**
 * 截取字符串并加身略号
 * @param str
 * @param length
 */
export function subText(str, length) {
  if (str.length === 0) {
    return '';
  }
  if (str.length > length) {
    return str.substr(0, length) + "...";
  } else {
    return str;
  }
}

/**
 * 单位转换-B转换到KB,MB,GB并保留两位小数
 * @param { number } fileSize
 */
export function formatFileSize(fileSize) {
  let temp;
  if (fileSize < 1024) {
    return fileSize + 'B';
  } else if (fileSize < (1024 * 1024)) {
    temp = fileSize / 1024;
    temp = temp.toFixed(2);
    return temp + 'KB';
  } else if (fileSize < (1024 * 1024 * 1024)) {
    temp = fileSize / (1024 * 1024);
    temp = temp.toFixed(2);
    return temp + 'MB';
  } else {
    temp = fileSize / (1024 * 1024 * 1024);
    temp = temp.toFixed(2);
    return temp + 'GB';
  }
}

/**
 * 判断数据类型
 * @param {*} target
 */
export function type(target) {
  let ret = typeof(target);
  let template = {
    "[object Array]": "array",
    "[object Object]":"object",
    "[object Number]":"number",
    "[object Boolean]":"boolean",
    "[object String]":'string'
  };

  if(target === null) {
    return 'null';
  }else if(ret === "object"){
    let str = Object.prototype.toString.call(target);
    return template[str];
  }else{
    return ret;
  }
}

/**
 * 生成指定范围随机数
 * @param { number } min
 * @param { number } max
 */
export const RandomNum = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

/**
 * 数组乱序
 * @param {array} arr
 */
export function arrScrambling(arr) {
  let array = arr;
  let index = array.length;
  while (index) {
    index -= 1;
    let randomIndex = Math.floor(Math.random() * index);
    let middleware = array[index];
    array[index] = array[randomIndex];
    array[randomIndex] = middleware
  }
  return array
}

/**
 * 数组交集
 * @param { array} arr1
 * @param { array } arr2
 */
export const similarity = (arr1, arr2) => arr1.filter(v => arr2.includes(v));

/**
 * 数组中某元素出现的次数
 * @param { array } arr
 * @param {*} value
 */
export function countOccurrences(arr, value) {
  return arr.reduce((a, v) => v === value ? a + 1 : a + 0, 0);
}

/**
 * 递归优化（尾递归）
 * @param { function } f
 */
export function tco(f) {
  let value;
  let active = false;
  let accumulated = [];

  return function accumulator() {
    accumulated.push(arguments);
    if (!active) {
      active = true;
      while (accumulated.length) {
        value = f.apply(this, accumulated.shift());
      }
      active = false;
      return value;
    }
  };
}

/**
 * 去除空格
 * @param { string } str 待处理字符串
 * @param  { number } type 去除空格类型 1-所有空格  2-前后空格  3-前空格 4-后空格 默认为1
 */
export function trim(str, type = 1) {
  if (type && type !== 1 && type !== 2 && type !== 3 && type !== 4) return;
  switch (type) {
    case 1:
      return str.replace(/\s/g, "");
    case 2:
      return str.replace(/(^\s)|(\s*$)/g, "");
    case 3:
      return str.replace(/(^\s)/g, "");
    case 4:
      return str.replace(/(\s$)/g, "");
    default:
      return str;
  }
}

/**
 * 大小写转换
 * @param { string } str 待转换的字符串
 * @param { number } type 1-全大写 2-全小写 3-首字母大写 其他-不转换
 */

export function turnCase(str, type) {
  switch (type) {
    case 1:
      return str.toUpperCase();
    case 2:
      return str.toLowerCase();
    case 3:
      return str[0].toUpperCase() + str.substr(1).toLowerCase();
    default:
      return str;
  }
}

/**
 * 随机16进制颜色
 * 方法一
 */
export function hexColor() {

  let str = '#';
  let arr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 'A', 'B', 'C', 'D', 'E', 'F'];
  for (let i = 0; i < 6; i++) {
    let index = Number.parseInt((Math.random() * 16).toString());
    str += arr[index]
  }
  return str;
}

/**
 * 随机16进制颜色
 * 方法二
 */
export const randomHexColorCode = () => {
  let n = (Math.random() * 0xfffff * 1000000).toString(16);
  return '#' + n.slice(0, 6);
};

/**
 * 转义html(防XSS攻击)
 * @param str
 */
export const escapeHTML = str =>{
  str.replace(
    /[&<>'"]/g,
    tag =>
      ({
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        "'": '&#39;',
        '"': '&quot;'
      }[tag] || tag)
  );
};

/**
 * 数字超过规定大小加上加号“+”，如数字超过99显示99+
 * @param { number } val 输入的数字
 * @param { number } maxNum 数字规定界限
 */
export const outOfNum = (val, maxNum) =>{
  val = val ? val-0 :0;
  if (val > maxNum ) {
    return `${maxNum}+`
  }else{
    return val;
  }
};
