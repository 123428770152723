export function getDevicesModel(): string {
  let model = '';
  const events = navigator.userAgent;
  if (events.indexOf('Android') > -1 || events.indexOf('Linux') > -1 || events.indexOf('Adr') > -1) {
    model = 'Android';
  } else if (events.indexOf('iPhone') > -1) {
    // 根据尺寸进行判断 苹果的型号
    if (window.screen.height === 812 && window.screen.width === 375) { // 进行操作，改变样式
      console.log('苹果X');
      model = 'iPhoneX';
    } else if (window.screen.height === 736 && window.screen.width === 414) {
      model = 'iPhone7P - iPhone8P - iPhone6';
    } else if (window.screen.height === 667 && window.screen.width === 375) {
      model = 'iPhone7 - iPhone8 - iPhone6';
    } else if (window.screen.height === 568 && window.screen.width === 320) {
      model = 'iPhone5';
    } else {
      model = 'iPhone4';
    }
  } else if (events.indexOf('Windows Phone') > -1) {
    model = 'Windows Phone';
  } else if (events.indexOf('iPad') > -1) {
    model = 'iPad';
  }
  return model;
}

// 获取userAgent
export const getUserAgent = () => window.navigator.userAgent;

export const getIsAndroid = (): boolean => getUserAgent().indexOf('Android') > -1;
export const getIsIOS = (): boolean => !!(getUserAgent().match(/\(i[^;]+;( U;)? CPU.+Mac OS X/));

export const getIsWeChat = (): boolean => /MicroMessenger/ig.test(getUserAgent());


/**
 * 获取页面宽高
 */
export const getWindowRect = (): { width: number, height: number } => {
  const page = document.documentElement || document.body;
  return {
    width: page.clientWidth,
    height: page.clientHeight,
  };
};

// 判断机型是否是iphoneX
export const isIphoneX = (): boolean => {
  // X XS, XS Max, XR
  const xSeriesConfig = [
    {
      devicePixelRatio: 3,
      width: 375,
      height: 812,
    },
    {
      devicePixelRatio: 3,
      width: 414,
      height: 896,
    },
    {
      devicePixelRatio: 2,
      width: 414,
      height: 896,
    },
  ];
  // web
  if (typeof window !== 'undefined' && window) {
    const isIos = /iphone/gi.test(window.navigator.userAgent);
    if (!isIos) return false;
    const {
      devicePixelRatio,
      screen,
    } = window;
    const {
      width,
      height,
    } = screen;
    return xSeriesConfig.some(item => item.devicePixelRatio === devicePixelRatio && item.width === width && item.height === height);
  }
  return false;
};
