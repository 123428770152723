// 显示加载
// @ts-ignore
export const loadShow = (title = '', mask = true) => wx.showLoading({ title, mask })

// 隐藏加载
// @ts-ignore
export const loadHide = () => wx.hideLoading()

// toast封装
export const toastShow = (title = '', mask = true, duration = 1500, icon = 'none') =>
  // @ts-ignore
  wx.showToast({
    title,
    mask,
    duration,
    icon
  })

/**
 * 根据选择器查询元素位置信息
 * @param {string} selector 类似于 CSS 的选择器，但仅支持下列语法。
 * ID选择器：#the-id
 * class选择器（可以连续指定多个）：.a-class.another-class
 * 子元素选择器：.the-parent > .the-child
 * 后代选择器：.the-ancestor .the-descendant
 * 跨自定义组件的后代选择器：.the-ancestor >>> .the-descendant
 * 多选择器的并集：#a-node, .some-other-nodes
 */
export const getEleRectBySelector = async (selector, _self) => {
  // @ts-ignore
  const query = wx.createSelectorQuery().in(_self)
  return new Promise((resolve) => {
    query
      .select(selector)
      .boundingClientRect(resolve)
      .exec()
  })
}
