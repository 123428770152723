import Fly from "flyio/dist/npm/fly";
import {from, Observable, of} from "rxjs";
import {IResult} from "../../../shared/models";
import {catchError, map} from "rxjs/operators";
import {FlyRequestConfig, FlyResponse} from "flyio";
import {ConfigModel} from "../../../shared/models";
import {requestCommon} from "../common";
import {IAccessor} from "../../../shared/models";
import {RequestEnum} from "../../../shared/enum";
import {FDEventEmitter, NO_SINGLE} from "../../utils/event-emitter";

const {
  baseConfig,
  requestBeforeList,
  responseAfterList,
  requestProcessHookList,
  requestErrorHookList,
  config: setConfig,
  getConfig,
  addRequestBeforeHook,
  removeRequestBeforeHook,
  addRequestProcessHook,
  addResponseAfterHook,
  removeResponseAfterHook,
  addRequestErrorHook,
  removeRequestErrorHook,
} = requestCommon()

export {
  addRequestBeforeHook,
  removeRequestBeforeHook,
  addRequestProcessHook,
  addResponseAfterHook,
  removeResponseAfterHook,
  addRequestErrorHook,
  removeRequestErrorHook,
}

let $http = null
export function getHttpClientForPc(config: ConfigModel) {
  const event = new FDEventEmitter(NO_SINGLE)
  let httpInstance = null
  if (!config.newInstance && $http) return $http
  if (config.newInstance || !$http) {
    httpInstance = new Fly()
  }
  if (!config.newInstance) {
    $http = httpInstance
  }
  httpInstance.config = {
    ...baseConfig,
    ...config,
  }

  // 请求开始前拦截
  httpInstance.interceptors.request.use(async (request: FlyRequestConfig) => {
    // 标识请求为h5类型
    request.headers['Device-Type'] = 4
    const { method } = request
    // 额外参数
    const params = {}
    if (!request.body) request.body = params;
    else request.body = { ...request.body, ...params }
    if (!config.hookDisable) {
      await requestBeforeList.execList(request, httpInstance)
    }
    return request
  });
  // 请求完成时拦截
  httpInstance.interceptors.response.use(async (response: FlyResponse) => {
    // 访问器对象
    const accessor: IAccessor = {
      task: event, // 任务对象
      repeatRequest: false, // 当前响应的请求是否需要重试
    }
    if (!config.hookDisable) {
      await responseAfterList.execList(response, httpInstance, accessor)
    }
    // 当前请求需要重试
    if (accessor.repeatRequest) {
      return await new Promise(resolve => {
        // 注册请求重试任务
        event.once(RequestEnum.ACCESSOR_REPEAT_REQUEST, async () => {
          const result = await getHttpClientForPc({ ...config, newInstance: true })
            .request(response.request)
          resolve(result)
        })
      })
    }
    if (config.filterResponseResult) return response.data
    return response
  }, (err) => {
    if (!config.hookDisable) {
      requestErrorHookList.execList(err)
    }
  })
  return httpInstance
}

/**
 * HttpClient
 */
export class HttpClient {
  error$!: Observable<any>

  /**
   * @param url
   * @param data
   * @param config
   */
  public get<T, U = string>(
    url: string,
    data?: any,
    config: any = {}
  ): Observable<IResult<T, U>> {
    config = {
      ...baseConfig,
      ...config,
    }
    if (data) {
      config.params = data
    }
    return from($http.get(url, config)).pipe(
      map((response: any) => response.data),
      catchError((error: Error | undefined) => {
        console.log(error)
        if (error === undefined) {
          // return message.error("请求出错")
          return
        }
        this.error$ = of(error)
        this.handleError()
        return this.error$
      })
    );
  }

  /**
   * @param url
   * @param data
   * @param config
   */
  public post<T, U = string>(
    url: string,
    data?: any,
    config: any = {}
  ): Observable<IResult<T, U>> {
    config = {
      ...baseConfig,
      ...config,
    }
    return from($http.post(url, data, config)).pipe(
      map((response: any) => response.data),
      catchError((error: Error | undefined) => {
        console.log(error)
        if (error === undefined) {
          // return message.error("请求出错");
          return
        }
        this.error$ = of(error)
        this.handleError()
        return this.error$
      })
    )
  }

  public upload(data: any, url: string = "/admin/api/file"): Promise<any> {
    return new Promise(function (resolve: Function, reject: Function) {
      var xhr = new XMLHttpRequest()
      xhr.open("POST", url, true)
      xhr.onload = function () {
        let res = JSON.parse(xhr.responseText)
        if (res.errno !== 0) {
          resolve(res)
        } else {
          resolve(res)
        }
      };
      xhr.onerror = function () {
        reject(xhr)
      };
      xhr.send(data)
    });
  }

  // 处理请求错误
  public handleError() {
    this.error$.subscribe((err) => {
      let msg = '接口请求错误'
      if (err && err.data) {
        if (err.data.code && err.data.msg) {
          msg = `${err.data.code}: ${err.data.msg}`
        } else if (err.status) {
          msg += err.status
        }
      }
      // message.error(msg);
      switch (err.status) {
        case 403:
          // do something...
          break;
        default:
        // do something...
      }
    });
  }
}

/**
 * 获取请求实例
 */
export function getHttpClient(cfg?: ConfigModel) {
  setConfig(cfg)
  // 根据配置项获取请求实例
  // 要求此函数是单例模式的，即多次调用返回第一次初始化的对象
  getHttpClientForPc(getConfig())
  return new HttpClient()
}
