/**
 * 获取当前时间戳
 */
import {repairZero} from "./utils";
import {subtract} from "./math";

// @ts-ignore
export const getTimestampForSecond = (): number => Date.parse(new  Date()); //精确到秒
export const getTimestampForMillisecond = (): number => Date.now();  //精确到毫秒

/**
 * 获取指定时间戳
 * @param time 指定时间
 */
export const getTimestampForAppoint = (time: any): number => (new Date(time)).getTime();

/**
 * 获取前一天时间戳
 */
export const getTimestampForPreDay = (): number => +new Date() - 24 * 60 * 60 * 1000;
/**
 * 获取后一天时间戳
 */
export const getTimestampForNextDay = (): number => +new Date() + 24 * 60 * 60 * 1000;

/**
 * 获取今日零点时间戳
 */
export const getTimestampForZeroPoint = (): number => new Date(new Date().toLocaleDateString()).getTime();

/**
 * 获取今日最晚时间 23:59:59的时间戳
 */
export const getTimestampForTodayLastTime = (): number => new Date(new Date().toLocaleDateString()).getTime()+24 * 60 * 60 * 1000 - 1;

/**
 * 获取当前时间的n天后的时间戳
 * @param {number} n 天数
 * @returns {Number} 返回值为时间毫秒值
 */
export const toNextTimes = (n: number): number => +new Date() + n * 86400000

/***
 * 返回本周第一天的时间
 * @return {*} WeekFirstDay 返回本周第一天的时间
 */
export function getWeekFirstDay(): Date{
  const nowDate = new Date();
  return new Date(+nowDate - (nowDate.getDay() - 1) * 86400000);
}

/**
 * 返回本周最后一天的时间
 * @return {*} WeekLastDay 返回本周最后一天的时间
 */
export function getWeekLastDay(): Date{
  const nowDate = new Date();
  let weekFirstDay = new Date(+nowDate - ( nowDate.getDay() - 1) * 86400000);
  return new Date((+weekFirstDay / 1000 + 6 * 86400) * 1000);
}

/**
 * 返回本月第一天的时间
 *  @return {*} MonthFirstDay 返回本月第一天的时间
 */
export function getMonthFirstDay(): Date{
  const nowDate = new Date();
  return new Date(nowDate.getFullYear(), nowDate.getMonth());
}

/**
 *  @return {*} MonthLastDay 返回本月最后一天的时间
 */
export function showMonthLastDay(): Date{
  const nowDate = new Date();
  const monthNextFirstDay = new Date(nowDate.getFullYear(),nowDate.getMonth() + 1);
  return new Date(+monthNextFirstDay - 86400000);
}

/**
 * 日期转时间戳
 * @param {String} time - 日期字符串，如'2018-8-8','2018,8,8','2018/8/8'
 * @returns {Number} 返回值为时间毫秒值
 */
export function timeToTimestamp (time: string): number {
  let date = new Date(time);
  return date.getTime();
}

/**
 * 格式化当前时间
 * @return {string} timeText 返回系统时间字符串
 */
export function getDataTimeSec(): string {
  const time = new Date();
  let weekDay;
  let year = time.getFullYear();
  let month = time.getMonth() + 1;
  let day = time.getDate();
  //获取时分秒
  let h: number|string = time.getHours();
  let m: number|string = time.getMinutes();
  let s: number|string = time.getSeconds();
  //检查是否小于10
  h = repairZero(h);
  m = repairZero(m);
  s = repairZero(s);
  let nowDay = time.getDay();
  switch (nowDay) {
    case 0: {
      weekDay = "星期日"
    }
      break;
    case 1: {
      weekDay = "星期一"
    }
      break;
    case 2: {
      weekDay = "星期二"
    }
      break;
    case 3: {
      weekDay = "星期三"
    }
      break;
    case 4: {
      weekDay = "星期四"
    }
      break;
    case 5: {
      weekDay = "星期五"
    }
      break;
    case 6: {
      weekDay = "星期六"
    }
      break;
    case 7: {
      weekDay = "星期日"
    }
      break;
  }

  return `${year}年$month}月${day}日 ${weekDay} ${h}:${m}:${s}`
}

/**
 * 返回指定时间戳之间的时间间隔
 * @param {*} startTime 开始时间的时间戳
 * @param {*} endTime 结束时间的时间戳
 * @return {string} str 返回时间字符串
 */
export function getTimeInterval(startTime: number, endTime: number): string {
  let runTime = parseInt(String((endTime - startTime) / 1000));
  let year = Math.floor(runTime / 86400 / 365);
  runTime = runTime % (86400 * 365);
  let month = Math.floor(runTime / 86400 / 30);
  runTime = runTime % (86400 * 30);
  let day = Math.floor(runTime / 86400);
  runTime = runTime % 86400;
  let hour = Math.floor(runTime / 3600);
  runTime = runTime % 3600;
  let minute = Math.floor(runTime / 60);
  runTime = runTime % 60;
  let second = runTime;
  let str = '';
  if (year > 0) {
    str = year + '年';
  }
  if (year <= 0 && month > 0) {
    str = month + '月';
  }
  if (year <= 0 && month <= 0 && day > 0) {
    str = day + '天';
  }
  if (year <= 0 && month <= 0 && day <= 0 && hour > 0) {
    str = hour + '小时';
  }
  if (year <= 0 && month <= 0 && day <= 0 && hour <= 0 && minute > 0) {
    str = minute + '分钟';
  }
  if (year <= 0 && month <= 0 && day <= 0 && hour <= 0 && minute <= 0 && second > 0) {
    str += second + '秒';
  }
  str += '前';
  return str;
}

// 将秒单位的时间片段格式化为{ day: 'xx', hour: 'xx', minute: 'xx', second: 'xx' }对象
export const timeFormat = (time: number): {
  day: number|string;
  hour: number|string;
  minute: number|string;
  second: number|string
} => ({
  day: repairZero(Math.floor(time / 86400)), // day
  hour: repairZero(Math.floor(time / 3600)), // hour
  minute: repairZero(Math.floor(time / 60) % 60), // minute
  second: repairZero(Math.floor(time % 60)), // second
});

/**
 * 获取[未来时间]到[未来之前时间]的[一段时间距离]
 * @return {Array<String>} 'hh:ii:ss'
 */
export const futureTimeAndAppointTimeDistance = (
  futureTime = Date.now(),
  currentTime = Date.now(),
  countDownTime = null,
  flag = false,
) => {
  let tf = '00:00:00:00'.split(':');
  let tf2;
  if (countDownTime !== null) {
    if (countDownTime <= 0) return tf;
    tf2 = timeFormat(countDownTime);
    return [flag ? tf2.hour % 24 : tf2.hour, tf2.minute, tf2.second, tf2.day];
  }
  if (futureTime <= currentTime) return tf;
  // 计算未来时间到指定时间的时间差
  const diffTime = parseInt(String(subtract(futureTime, currentTime)));
  tf2 = timeFormat(diffTime);
  return [flag ? tf2.hour % 24 : tf2.hour, tf2.minute, tf2.second, tf2.day];
};

/**
 * 日期格式化
 * @param str
 * @param fmt
 */
export const formatDate = (str: any, fmt: string): string => {
  if (!str) return '';
  const time = new Date(str * 1000);
  const v = {
    m: time.getMonth() + 1,
    d: time.getDate(),
    h: time.getHours(),
    i: time.getMinutes(),
    s: time.getSeconds(),
    S: time.getMilliseconds(),
  };
  if (new RegExp(/(y+)/).test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (`${time.getFullYear()}`).slice(4 - RegExp.$1.length));
  }
  Object.keys(v).forEach((key) => {
    if (new RegExp(`(${key}+)`).test(fmt)) {
      fmt = fmt.replace(RegExp.$1, `${v[key]}`.length === 1 ? `0${v[key]}` : `${v[key]}`);
    }
  });
  return fmt;
}

/**
 * 判断是否为闰年
 * @param  {number} year 要判断的年份
 * @return {boolean} 返回布尔值
 */
export function leapYear(year): boolean {
  return !(year % (year % 100 ? 4 : 400));
}

/**
 * 返回两个年份之间的闰年
 * @param  {number} start 开始年份
 * @param  {number} end 结束年份
 * @return {array}  arr 返回符合闰年的数组
 */
export function leapYears(start: number, end: number): number[] {
  let arr: number[] = [];
  for (let i = start; i < end; i++) {
    if (leapYear(i) ) {
      arr.push(i)
    }
  }
  return arr
}

/**
 * 判断时间格式是否有效-短时间，如 (10:24:06)
 * @param  {string} str 需要验证的短时间
 * @return {boolean} 返回布尔值
 */
export function isTime(str): boolean {
  let a = str.match(/^(\d{1,2})(:)?(\d{1,2})\2(\d{1,2})$/);
  if (a == null) { return false; }
  if (a[1] >= 24 || a[3] >= 60 || a[4] >= 60) {
    return false
  }
  return true;
}

/**
 * 判断时间格式是否有效-短日期，形如 (2019-10-24)
 * @param  {string} str 需要验证的短时间
 * @return {boolean} 返回布尔值
 */
export function strDateTime(str): boolean {
  var result = str.match(/^(\d{1,4})(-|\/)(\d{1,2})\2(\d{1,2})$/);
  if (result == null) return false;
  var d = new Date(result[1], result[3] - 1, result[4]);
  return (d.getFullYear() == result[1] && d.getMonth() + 1 == result[3] && d.getDate() == result[4]);
}

/**
 * 判断时间格式是否有效-长日期时间，形如 (2019-10-24 10:24:06)
 * @param  {string} str 需要验证的短时间
 * @return {boolean} 返回布尔值
 */
export function strDateTime2(str): boolean {
  var result = str.match(/^(\d{4})(-|\/)(\d{1,2})\2(\d{1,2}) (\d{1,2}):(\d{1,2}):(\d{1,2})$/);
  if (result == null) return false;
  var d = new Date(result[1], result[3] - 1, result[4], result[5], result[6], result[7]);
  return (d.getFullYear() == result[1] && (d.getMonth() + 1) == result[3] && d.getDate() == result[4] && d.getHours() == result[5] && d.getMinutes() == result[6] && d.getSeconds() == result[7]);
}

/**
 * 验证日期大小
 * 例："2019-10-24" 和 "2019-10-25"
 * @param  {string} d1需要验证的日期1
 * @param  {string} d2需要验证的日期2
 * @return {boolean} 返回布尔值
 */
export function compareDate(d1: any, d2: any): boolean {
  return ((new Date(d1.replace(/-/g, "\/"))) < (new Date(d2.replace(/-/g, "\/"))));
}

/**
 * 验证一个日期是不是今天
 * @param  {string} val 需要验证的日期
 * @return {boolean} 返回布尔值
 */
export function isToday(val): boolean{
  return new Date().toLocaleDateString() == new Date(val).toLocaleDateString();
}

/**
 * 验证传入的日期是否是昨天
 * @param  {string} val 需要验证的日期
 * @return {boolean} 返回布尔值
 */
export function isYesterday(val): boolean {
  var today = new Date();
  var yesterday = new Date(getTimestampForMillisecond() - 1000 * 60 * 60 * 24);
  var test = new Date(val);
  return yesterday.getFullYear() === test.getFullYear() && yesterday.getMonth() === test.getMonth() && yesterday.getDate() === test.getDate()
}

/**
 * 设置几天后的日期
 * @param  {string} date 起始日期
 * @param  {number} day 向后的天数
 * @return {string} 返回想要得到的日期
 */
export function convertDate (date, day): string {
  let tempDate = new Date(date);
  tempDate.setDate(tempDate.getDate() + day);
  const Y = tempDate.getFullYear();
  const M = repairZero(tempDate.getMonth());
  const D = repairZero(tempDate.getDate());
  return `${Y}-${M}-${D}`;
}
