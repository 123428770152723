import Fly from "flyio/dist/npm/wx";
import {requestCommon} from "../common";
import {FlyRequestConfig, FlyResponse} from "flyio";
import {FDEventEmitter, NO_SINGLE} from "../../utils/event-emitter";
import {ConfigModel} from "../../../shared/models";
import {IAccessor} from "../../../shared/models";
import {RequestEnum} from "../../../shared/enum";

const {
  baseConfig,
  requestBeforeList,
  responseAfterList,
  requestProcessHookList,
  requestErrorHookList,
  config: setConfig,
  getConfig,
  addRequestBeforeHook,
  removeRequestBeforeHook,
  addRequestProcessHook,
  addResponseAfterHook,
  removeResponseAfterHook,
  addRequestErrorHook,
  removeRequestErrorHook,
} = requestCommon()

export {
  addRequestBeforeHook,
  removeRequestBeforeHook,
  addRequestProcessHook,
  addResponseAfterHook,
  removeResponseAfterHook,
  addRequestErrorHook,
  removeRequestErrorHook,
}

export function getHttpClientForMp(config: ConfigModel) {
  const event = new FDEventEmitter(NO_SINGLE)
  let $http = null
  return (() => {
    let httpInstance = null
    if (!config.newInstance && $http) return $http
    if (config.newInstance || !$http) {
      httpInstance = new Fly()
    }
    if (!config.newInstance) {
      $http = httpInstance
    }
    httpInstance.config = {
      ...baseConfig,
      ...config,
    }

    // 请求开始前拦截
    httpInstance.interceptors.request.use(async (request: FlyRequestConfig) => {
      // 标识请求为小程序类型
      request.headers['Device-Type'] = 5
      const { method } = request
      // 额外参数
      const params = {}
      if (!request.body) request.body = params;
      else request.body = { ...request.body, ...params }
      if (!config.hookDisable) {
        await requestBeforeList.execList(request, httpInstance)
      }
      return request
    });
    // 请求完成时拦截
    httpInstance.interceptors.response.use(async (response: FlyResponse) => {
      // 访问器对象
      const accessor: IAccessor = {
        task: event, // 任务对象
        repeatRequest: false, // 当前响应的请求是否需要重试
      }
      if (!config.hookDisable) {
        await responseAfterList.execList(response, httpInstance, accessor)
      }
      // 当前请求需要重试
      if (accessor.repeatRequest) {
        return await new Promise(resolve => {
          // 注册请求重试任务
          event.once(RequestEnum.ACCESSOR_REPEAT_REQUEST, async () => {
            const result = await getHttpClientForMp({ ...config, newInstance: true })
              .request(response.request)
            resolve(result)
          })
        })
      }
      if (config.filterResponseResult) return response.data
      return response
    }, (err) => {
      if (!config.hookDisable) {
        requestErrorHookList.execList(err)
      }
    })
    return httpInstance
  })()
}

/**
 * 获取请求实例
 */
export function getHttpClient(cfg?: ConfigModel) {
  setConfig(cfg)
  // 根据配置项获取请求实例，并通过分别实现
  // getHttpClientForH5、getHttpClientForMp、getHttpClientForPc
  // 并要求此函数是单例模式的，即多次调用返回第一次初始化的对象
  return getHttpClientForMp(getConfig())
}
