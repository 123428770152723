import { ExecutableQueue, JudgmentFormula} from "../utils/executable-queue";
import {ConfigModel} from "../../shared/models";
import {PlatformEnum} from "../../shared/enum";

export function requestCommon() {

  const baseConfig = {
    parseJson: true,
    timeout: "10s"
  }

  // 请求前置钩子函数列表
  const requestBeforeList: ExecutableQueue = new ExecutableQueue()

  // 请求响应钩子函数列表
  const responseAfterList: ExecutableQueue = new ExecutableQueue()

  // 请求进度更新钩子函数列表
  const requestProcessHookList: ExecutableQueue = new ExecutableQueue(false)

  // 请求出错钩子列表
  const requestErrorHookList: ExecutableQueue = new ExecutableQueue(false)

  let configCache: ConfigModel = {
    platform: PlatformEnum.H5
  }

  /**
   * 配置方法，配置基路径，选择请求实例等
   */
  function config(options: ConfigModel): void {
    // 设置请求配置对象
    configCache = { ...configCache, ...options }
  }

  /**
   * 获取配置方法
   */
  function getConfig(): ConfigModel {
    return configCache
  }

  /**
   * 添加请求前钩子
   */
  function addRequestBeforeHook(func: JudgmentFormula) {
    // 此函数可以多次调用，将传人的钩子函数添加到requestBeforeList列表中
    // func可以直接返回boolean类型或者Promise，并根据返回的值决定是否往下执行
    // 当func返回false时结束整个请求，并停止后续钩子
    requestBeforeList.add(func)
  }

  /**
   * 删除请求前置钩子函数
   * @param func
   */
  function removeRequestBeforeHook(func: JudgmentFormula) {
    // 删除传人函数与requestBeforeList列表中相同的钩子函数
    requestBeforeList.remove(func)
  }

  /**
   * 添加请求进度钩子
   */
  function addRequestProcessHook(func: JudgmentFormula /* (percent: number 取值：0-1) => boolean|Promise<boolean> */) {
    // 向钩子函数发送请求进度值
    requestProcessHookList.add(func)
  }

  /**
   * 添加请求响应钩子
   * @param func 钩子函数
   */
  function addResponseAfterHook(func: JudgmentFormula) {
    // 此函数可以多次调用，将传人的钩子函数添加到responseAfterList列表中
    // func可以直接返回boolean类型或者Promise，并根据返回的值决定是否往下执行
    // 当func返回false时结束整个请求，并停止后续钩子
    responseAfterList.add(func);
  }

  function removeResponseAfterHook(func: JudgmentFormula) {
    // 删除传人函数与responseAfterList列表中相同的钩子函数
    responseAfterList.remove(func)
  }

  function addRequestErrorHook(func: JudgmentFormula) {
    requestErrorHookList.add(func)
  }

  function removeRequestErrorHook(func: JudgmentFormula) {
    requestErrorHookList.remove(func)
  }


  return {
    baseConfig,
    requestBeforeList,
    responseAfterList,
    requestProcessHookList,
    requestErrorHookList,
    config,
    getConfig,
    addRequestBeforeHook,
    removeRequestBeforeHook,
    addRequestProcessHook,
    addResponseAfterHook,
    removeResponseAfterHook,
    addRequestErrorHook,
    removeRequestErrorHook,
  }
}

